export interface IHistory {
  _id: string;
  booking?: any;
  project?: any;
  task?: any;
  projectVehicle?: any;
  vehicle?: any;
  comment?: any;
  client?: any;
  vendor?: any;

  action: string;
  changes?: { field?: string; from?: string | string[]; to: string | string[]; }[]
  text?: string;
  date: Date;
  author: any;
}

export enum BookingHistoryAction {
  created = 'booking_created',
  changeStatus = 'booking_changeStatus',
  addVehicles = 'booking_addVehicles',
  deleteVehicles = 'booking_deleteVehicles',
  hideVehicles = 'booking_hideVehicles',
  showVehicles = 'booking_showVehicles',
  updateVehicle = 'booking_updateVehicle',
  addCommentToVehicle = 'booking_commentToVehicle',
  changeVehicesOrder = 'booking_changeVehicesOrder',
  updateMiscellaneous = 'booking_updateMiscellaneous',
  addNote = 'booking_addNote',
  updateNote = 'booking_updateNote',
  deleteNote = 'booking_deleteNote',
  updateQuestionnaire = 'booking_updateQuestionnaire',
  uploadDocument = 'booking_uploadDocument',
  deleteDocuments = 'booking_deleteDocuments',
  addTask = 'booking_addTask',
  moveTask = 'booking_moveTask',
  confirmDates = 'booking_confirmDates',
}

export enum VehicleHistoryAction {
  created = 'vehicle_created',
  enrolled = 'vehicle_enrolled',
  changeStatus = 'vehicle_changeStatus',
  updateVehicle = 'vehicle_updateVehicle',
  addComment = 'vehicle_addComment',
  replyToComment = 'vehicle_replyToComment',
  deleteComment = 'vehicle_deleteComment',
  vetted = 'vehicle_vetted',
}

export enum VendorHistoryAction {
  created = 'vendor_created',
  enrolled = 'vendor_enrolled',
  deleted = 'vendor_deleted',
  update = 'vendor_update',
  addComment = 'vendor_addComment',
  replyToComment = 'vendor_replyToComment',
  deleteComment = 'vendor_deleteComment',
  vet = 'vendor_vet',
}
