import { Container, IconButton, Tooltip } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'src/routes/hook';
import { paths } from 'src/routes/paths';
import { useSettingsContext } from 'src/components/settings';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import Box from '@mui/material/Box';
// sections
import BillingCompanyView from 'src/sections/billing-company/view/billing-company-view';
import { useGetBillingCompany } from '../../api/billing-company';
import Iconify from '../../components/iconify';
import BillingCompanyEditDialogForm from '../../sections/billing-company/billing-company-edit-dialog-form';
import { useBoolean } from '../../hooks/use-boolean';

// ----------------------------------------------------------------------

export default function BillingCompanyPage() {
  const params = useParams();
  const settings = useSettingsContext();
  const { id } = params;

  const { company } = useGetBillingCompany(id!);
  const dialogForm = useBoolean();

  return (
    <>
      <Helmet>
        <title> Company Page</title>
      </Helmet>

      <Container maxWidth={settings.themeStretch ? false : 'lg'}>
        <Box sx={{mt: 1, display: 'flex', alignItems: 'flex-start'}}>
          <CustomBreadcrumbs
            heading={company?.name || '*no title'}
            links={[
              { name: 'Dashboard', href: paths.dashboard.root },
              { name: 'Clients', href: paths.dashboard.clients.root },
              { name: company?.name || '*no title'},
            ]}
            sx={{mb: { xs: 3, md: 5 },}}
          />

          <Tooltip title="Edit">
            <IconButton onClick={dialogForm.onTrue}>
              <Iconify icon="solar:pen-bold" color="black" />
            </IconButton>
          </Tooltip>
        </Box>

        <BillingCompanyView id={id!}/>
      </Container>

      {company && <BillingCompanyEditDialogForm value={company.name} id={company._id} open={dialogForm.value} onClose={dialogForm.onFalse} />}
    </>
  );
}
