// @mui
import Grid from '@mui/material/Unstable_Grid2';
// components
import { useGetBillingCompany } from 'src/api/billing-company';
import { LoadingScreen } from 'src/components/loading-screen';

import AccountBillingPayment from '../account-billing-payment';
import AccountBillingHistory from '../account-billing-history';
import AccountBillingAddress from '../account-billing-address';

// ----------------------------------------------------------------------

type Props = {
  id: string;
};

export default function BillingCompanyView({ id }: Props) {
  const { company, isLoading } = useGetBillingCompany(id);

  return(
    isLoading ? (
      <LoadingScreen />
    ) : (
      <Grid container spacing={5} disableEqualOverflow>
        <Grid xs={12} md={8}>

          <AccountBillingPayment cards={[]} />

          <AccountBillingAddress companyId={company._id} addressBook={company?.addressBook || []} />
        </Grid>

        <Grid xs={12} md={4}>
          <AccountBillingHistory invoices={[]} />
        </Grid>
      </Grid>
    )
  )
}
