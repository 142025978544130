import dayjs from 'dayjs';

// ----------------------------------------------------------------------

type InputValue = Date | string | number | null | undefined;

export function fDate(date: InputValue, newFormat?: string) {
  const fm = newFormat || 'MMM DD YYYY';

  return date ? dayjs(date).tz('America/Los_Angeles').format(fm) : '';
}

export function fDateTime(date: InputValue, newFormat?: string) {
  const fm = newFormat || 'MMM DD YYYY HH:mm';

  return date ? dayjs(date).tz('America/Los_Angeles').format(fm) : '';
}

export function fTimestamp(date: InputValue) {
  return date ? dayjs(date).tz('America/Los_Angeles').valueOf() : '';
}

export function fToNow(date: InputValue) {
  return date ? dayjs(date).tz('America/Los_Angeles').toNow(true) : '';
}

export function fFromNow(date: InputValue) {
  return date ? dayjs(date).tz('America/Los_Angeles').fromNow() : '';
}
