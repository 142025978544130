import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import { AuthGuard } from 'src/auth/guard';
// layouts
import DashboardLayout from 'src/layouts/dashboard';
// components
import { LoadingScreen } from 'src/components/loading-screen';
import BillingCompanyPage from 'src/pages/dashboard/billing-company';

// ----------------------------------------------------------------------
const Page404 = lazy(() => import('src/pages/404'));

// OLD PROJECT
const OldProjectsListPage = lazy(() => import('src/pages/dashboard/oldProjects/list'));
const OldProjectDetailsPage = lazy(() => import('src/pages/dashboard/oldProjects/view'));

// PROJECT
const ProjectsListPage = lazy(() => import('src/pages/dashboard/projects/list'));
const ProjectCreatePage = lazy(() => import('src/pages/dashboard/projects/new'));
const ProjectDetailsPage = lazy(() => import('src/pages/dashboard/projects/view'));
const VehiclesSelectForProjectPage = lazy(() => import('src/pages/dashboard/vehicles/select-for-project'));

// BOOKING
const BookingsListPage = lazy(() => import('src/pages/dashboard/booking/list'));
const BookingsDetailsPage = lazy(() => import('src/pages/dashboard/booking/view'));

// VENDOR
const VendorViewPage = lazy(() => import('src/pages/dashboard/vendors/view'));
const VendorEditPage = lazy(() => import('src/pages/dashboard/vendors/edit'));
const VendorListPage = lazy(() => import('src/pages/dashboard/vendors/list'));
const VendorCreatePage = lazy(() => import('src/pages/dashboard/vendors/new'));

// VEHICLE
const VehicleListPage = lazy(() => import('src/pages/dashboard/vehicles/list'));
const VehicleCreatePage = lazy(() => import('src/pages/dashboard/vehicles/new'));
const VehicleViewPage = lazy(() => import('src/pages/dashboard/vehicles/view'));
const VehicleEditPage = lazy(() => import('src/pages/dashboard/vehicles/edit'))

// CLIENT
const ClientListPage = lazy(() => import('src/pages/dashboard/clients/list'))
const ClientCreatePage = lazy(() => import('src/pages/dashboard/clients/new'));
const ClientAccountPage = lazy(() => import('src/pages/dashboard/clients/account'));
const ClientEditPage = lazy(() => import('src/pages/dashboard/clients/edit'));

// USER
const UserListPage = lazy(() => import('src/pages/dashboard/users/list'));
const UserCreatePage = lazy(() => import('src/pages/dashboard/users/new'));
const UserAccountPage = lazy(() => import('src/pages/dashboard/users/account'));

// FILE MANAGER
const FileManagerPage = lazy(() => import('src/pages/dashboard/file-manager'));
const CalendarPage = lazy(() => import('src/pages/dashboard/calendar'));
const KanbanPage = lazy(() => import('src/pages/dashboard/kanban'));

// ----------------------------------------------------------------------

export const dashboardRoutes = [
  {
    path: 'dashboard',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      { element: <Page404 />, index: true },
      { path: 'kanban', element: <KanbanPage /> },
      { path: 'calendar', element: <CalendarPage /> },
      {
        path: 'projects',
        children: [
          { element: <ProjectsListPage />, index: true},
          { path: 'list', element: <ProjectsListPage />},
          { path: 'new', element: <ProjectCreatePage /> },
          { path: ':id', element: <ProjectDetailsPage /> },
          { path: ':id/select-vehicles', element: <VehiclesSelectForProjectPage /> },
        ]
      },
      {
        path: 'bookings',
        children: [
          { element: <BookingsListPage />, index: true},
          { path: 'list', element: <BookingsListPage />},
          { path: ':id', element: <BookingsDetailsPage /> },
          { path: ':id/select-vehicles', element: <VehiclesSelectForProjectPage /> },
        ]
      },
      { path: 'invoices', element: <Page404 /> },
      {
        path: 'vehicles',
        children: [
          { element: <VehicleListPage />, index: true },
          { path: 'list', element: <VehicleListPage /> },
          { path: 'new', element: <VehicleCreatePage /> },
          { path: ':id', element: <VehicleViewPage />},
          { path: ':id/edit', element: <VehicleEditPage /> },
        ],
      },
      {
        path: 'vendors',
        children: [
          { element: <VendorListPage />, index: true },
          { path: 'list', element: <VendorListPage /> },
          { path: 'new', element: <VendorCreatePage /> },
          { path: ':id', element: <VendorViewPage /> },
          { path: ':id/edit', element: <VendorEditPage /> },
        ],
      },
      {
        path: 'users',
        children: [
          { element: <UserListPage />, index: true },
          { path: 'list', element: <UserListPage />},
          { path: 'new', element: <UserCreatePage />},
          {path: 'account/:id', element: <UserAccountPage />}
        ],
      },
      {
        path: 'clients',
        children: [
          { element: <ClientListPage />, index: true},
          { path: 'list', element: <ClientListPage />},
          { path: 'new', element: <ClientCreatePage /> },
          { path: 'account/:id', element: <ClientAccountPage /> },
          { path: ':id/edit', element: <ClientEditPage /> },
        ]
      },
      {
        path: 'billing-company',
        children: [
          { path: ':id', element: <BillingCompanyPage /> },
        ]
      },
      {
        path: 'documents',
        children: [
          { element: <FileManagerPage />, index: true },
        ],
      },
      {
        path: 'old-projects',
        children: [
          { element: <OldProjectsListPage />, index: true},
          { path: 'list', element: <OldProjectsListPage />},
          { path: ':id', element: <OldProjectDetailsPage /> },
        ]
      },
    ],
  },
];
