import useSWR, { mutate } from 'swr';
import { useMemo } from 'react';
// utils
import { fetcher, endpoints, IPageParameters, updateRequest } from 'src/utils/axios';
import { IBillingProductionCompany, IInitBillingProductionCompany } from 'src/types/billing-company.interface';
import { IProject } from 'src/types/project.interface';
import { IOldProject } from 'src/types/oldProject.interface';
import { IAddressItem } from 'src/types/address';

export function useGetBillingCompanies(filters: IPageParameters) {
  const islimitInFilters = filters.urlFilters?.includes('limit');
  const isOrderByInFilters = filters.urlFilters?.includes('orderBy');
  const filtersString = `${filters.page}?`
    + `${filters.urlFilters}${islimitInFilters ? '' :`&limit=${filters.limit}`}`
    + `${isOrderByInFilters ? '' : `&orderBy=${filters.orderBy}&order=${filters.order}`}
  `
  const URL = endpoints.billingCompany.page(filtersString);

  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher);

  const memoizedValue = useMemo(
    () => ({
      companies: (data?.companies as IBillingProductionCompany[]) || [],
      count: data?.count,
      companiesLoading: isLoading,
      companiesError: error,
      companiesValidating: isValidating,
      companiesEmpty: !isLoading && !data?.companies.length,
    }),
    [data?.companies, data?.count, error, isLoading, isValidating]
  );

  return memoizedValue;
}

export function useGetBillingCompany(id: string) {
  const URL = id ? endpoints.billingCompany.details(id) : '';

  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher);

  const memoizedValue = useMemo(
    () => ({
      company: (data as IBillingProductionCompany) || undefined,
      isLoading,
      error,
      isValidating,
      mutate
    }),
    [data, error, isLoading, isValidating]
  );

  return memoizedValue;
}

export function useGetProjectsForCompany(id: string){
  const URL = endpoints.billingCompany.projects(id);

  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher);

  const memoizedValue = useMemo(
    () => ({
      projects: (data?.projects as IProject[]) || [],
      oldProjects: (data?.oldProjects as IOldProject[]) || [],
      isLoading,
      error,
      isValidating,
      projectsEmpty: !isLoading && !data?.length,
      mutate
    }),
    [data, error, isLoading, isValidating]
  );

  return memoizedValue;
}

export async function createBillingCompany(billingCompany: IInitBillingProductionCompany) {
  const URL = endpoints.billingCompany.create;
  const res = await updateRequest(URL, billingCompany)

  return res;
}

export async function updateBillingCompany(data: IBillingProductionCompany) {
  const URL = endpoints.billingCompany.update;
  const res = await updateRequest(URL, data)

  return res;
}

export async function editBillingCompanyName(id: string, data: IInitBillingProductionCompany) {
  const URL = endpoints.billingCompany.updateName(id);
  await updateRequest(URL, data)

  mutate(endpoints.billingCompany.details(id));
}


export async function addBillingAddress(id: string, data: IAddressItem) {
  const URL = endpoints.billingCompany.addAddress(id);
  await updateRequest(URL, data)

  mutate(endpoints.billingCompany.details(id));
}

export async function editBillingAddress(id: string, data: IAddressItem) {
  const URL = endpoints.billingCompany.editAddress(id);
  await updateRequest(URL, data)

  mutate(endpoints.billingCompany.details(id));
}

export async function setDefaultAddress(id: string, addressId: string) {
  const URL = endpoints.billingCompany.setDefaultAddress(id);
  await updateRequest(URL, {id: addressId})

  mutate(endpoints.billingCompany.details(id));
}

export async function deleteAddress(id: string, addressId: string) {
  const URL = endpoints.billingCompany.deleteAddress(id);
  await updateRequest(URL, {id: addressId})

  mutate(endpoints.billingCompany.details(id));
}

