import { memo, useMemo } from "react";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// @mui
import LoadingButton from "@mui/lab/LoadingButton";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
// components
import { useSnackbar } from "src/components/snackbar";
import FormProvider, { RHFTextField } from "src/components/hook-form";
import { editBillingCompanyName } from "src/api/billing-company";
import { Box } from "@mui/material";

type Props = {
  open: boolean;
  onClose: VoidFunction;
  value: string;
  id: string
};

function BillingCompanyEditDialogForm({ open, onClose, value, id }: Props) {
  const { enqueueSnackbar } = useSnackbar();

  const BillingCompanySchema = Yup.object().shape({
    name: Yup.string().required("Name is required")
  });

  const defaultValues = useMemo(
    () => ({
      name: value || ""
    }),
    [value]
  );

  const methods = useForm({
    resolver: yupResolver(BillingCompanySchema),
    defaultValues
  });

  const {
    handleSubmit,
    formState: { isSubmitting }
  } = methods;

  const onSubmit = handleSubmit(async (data) => {
    try {
      await editBillingCompanyName(id as string, data);
      enqueueSnackbar(`${data.name} edited!`);
      onClose();
    } catch (error) {
      console.error(error);
    }
  });

  return (
    <Dialog
      fullWidth
      maxWidth={false}
      open={open}
      onClose={() => onClose()}
      PaperProps={{
        sx: { maxWidth: 720 }
      }}
    >
      <FormProvider methods={methods} onSubmit={onSubmit}>
        <DialogTitle>Edit Company Name</DialogTitle>
        <DialogContent>
          <Box rowGap={3} columnGap={2} sx={{ mt: 1 }}>
            <RHFTextField name="name" label="Billing Company Name" />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={() => onClose()}>Cancel</Button>
          <LoadingButton type="submit" variant="contained" loading={isSubmitting}>Save</LoadingButton>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
}

export default memo(BillingCompanyEditDialogForm);
