import axios, { AxiosRequestConfig } from 'axios';
// config
import { HOST_API } from 'src/config-global';

// ----------------------------------------------------------------------

const axiosInstance = axios.create({ baseURL: HOST_API });

axiosInstance.interceptors.response.use(
  (res) => res,
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

export default axiosInstance;

// ----------------------------------------------------------------------

export const fetcher = async (args: string | [string, AxiosRequestConfig]) => {
  const [url, config] = Array.isArray(args) ? args : [args];

  const res = await axiosInstance.get(url, { ...config });

  return res.data;
};

export const deleteRequest = async (args: string | [string, AxiosRequestConfig]) => {
  const [url, config] = Array.isArray(args) ? args : [args];

  const res = await axiosInstance.delete(url, { ...config });

  return res.data;
};

export const updateRequest = async (url: string, data: any) => {
  const res = await axiosInstance.post(url, data);

  return res.data;
};


// ----------------------------------------------------------------------
export type IPageParameters = {
  page: number;
  limit: number;
  orderBy?: string;
  order?: "asc" | "desc";
  urlFilters?: string;
}

export const endpoints = {
  chat: '/api/chat',
  auth: {
    me: '/auth/admin',
    login: '/auth/login',
    register: '/auth/register',
    verify: '/auth/verify',
  },
  kanban: {
    getBoard: '/kanban/get-board',
    getColumn: (id: string) => `/kanban/get-column/${id}`,
    createColumn: '/kanban/create-column',
    updateColumn:'/kanban/update-column',
    moveColumn: '/kanban/move-column',
    clearColumn: '/kanban/clear-column',
    deleteColumn: (id: string) => `/kanban/column/${id}`,
    createTask: '/kanban/create-task',
    createTaskForBooking: '/kanban/create-task-for-booking',
    updateTask: '/kanban/update-task',
    deleteTask: '/kanban/delete-task',
    moveTaskToSameList: '/kanban/move-task-to-same-list',
    moveTaskToDifferentList: '/kanban/move-task-to-different-list',
  },
  task: {
    all: '/task/all',
    details: (id?: string) => id ? `/task/${id}` : '',
    moveTaskToDifferentList: '/task/move-task-to-different-list',
    assign: '/task/assign',
    unassign: '/task/unassign',
    addLabel: '/task/add-label',
    removeLabel: '/task/remove-label',
    updateLabels: '/task/update-labels',
    changeDueDate:'/task/change-due-date',
    addComment: '/task/add-comment',
    addTodo: '/task/add-todo',
    saveDueDate: '/task/save-due-date',
    savePriority: '/task/save-priority',
    saveName: '/task/save-name',
    complete: '/task/complete',
    showOnTheJobBoard: '/task/show-on-the-job-board',
    delete: (id: string) => `/task/${id}`,
  },
  calendar: {
    get: (startDate: string, endDate: string, view: string) => `/calendar/get-events/?startDate=${startDate}&endDate=${endDate}&view=${view}`,
  },
  vendors: {
    page: (filters: string) => `/vendors/page/${filters}`,
    details: (id: string) => `/vendors/${id}`,
    references: (id: string) => `/vendors/references/${id}`,
    update: '/vendors/update',
    create: '/vendors/create',
    vet: '/vendors/vet',
    delete: (id: string) => `/vendors/${id}/delete`,
    addComment: (id: string) => `/vendors/${id}/add-comment`,
    replyToComment: (id: string) => `/vendors/${id}/reply-to-comment`,
    deleteComment: (id: string) => `/vendors/${id}/delete-comment`,
  },
  vehicles: {
    page: (filters: string) => `/vehicles/page/${filters}`,
    count: '/vehicles/count-by-status',
    details: (id: string) => `/vehicles/id/${id}`,
    update: '/vehicles/update',
    updateMany: '/vehicles/update-many',
    create: '/vehicles/create',
    delete: (id: string) => `/vehicles/${id}/delete`,
    changeStatus: '/vehicles/change-status',
    vetVehicle: '/vehicles/vet',
    forVendor: (id: string) => `/vehicles/for-vendor/${id}`,
    addComment: (id: string) => `/vehicles/${id}/add-comment`,
    replyToComment: (id: string) => `/vehicles/${id}/reply-to-comment`,
    deleteComment: (id: string) => `/vehicles/${id}/delete-comment`,
    bookVehicle: (id: string) => `/vehicles/${id}/book`,
    unbookVehicle: (id: string) => `/vehicles/${id}/unbook`,
  },
  clients: {
    page: (filters: string) => `/clients/page/${filters}`,
    details: (id: string) => `/clients/${id}`,
    references: (id: string) => `/clients/references/${id}`,
    update: '/clients/update',
    create: '/clients/create',
    delete: (id: string) => `/clients/${id}/delete`,
    projects: (id: string) => `projects/for-client/${id}`
  },
  billingCompany: {
    page: (filters: string) => `/billing-company/page/${filters}`,
    details: (id: string) => `/billing-company/${id}`,
    create: '/billing-company/create',
    update: '/billing-company/update',
    updateName: (id: string) => `/billing-company/${id}/update-name`,
    addAddress: (id: string) => `/billing-company/${id}/add-address`,
    editAddress: (id: string) => `/billing-company/${id}/edit-address`,
    deleteAddress: (id: string) => `/billing-company/${id}/delete-address`,
    setDefaultAddress: (id: string) => `/billing-company/${id}/set-default-address`,
    projects: (id: string) => `/projects/for-company/${id}`,
  },
  users: {
    page: (filters: string) => `/users/page/${filters}`,
    details: (id: string) => `/users/${id}`,
    create: '/users/create',
    update: '/users/update',
    delete: (id: string) => `/users/${id}/delete`,
  },
  oldProjects: {
    page: (filters: string) => `/old-projects/page/${filters}`,
    count: '/old-projects/count-by-status',
    details: (id: string) => `/old-projects/${id}`,
    delete: (id: string) => `/old-projects/${id}/delete`,
    changeStatus: (id: string) => `/old-projects/${id}/change-status`,
  },
  projects: {
    page: (filters: string) => `/projects/page/${filters}`,
    count: '/projects/count-by-status',
    details: (id: string) => `/projects/${id}`,
    create: '/projects/create',
    updateQuestionnaire: (id: string) => `/projects/${id}/update-questionnaire`,
    delete: (id: string) => `/projects/${id}/delete`,
    changeStatus: (id: string) => `/projects/${id}/change-status`,
    changeBillingCompany: (id: string) => `/projects/${id}/change-billing-company`,
    addComment: (id: string) => `/projects/${id}/add-comment`,
    replyToComment: (id: string) => `/projects/${id}/reply-to-comment`,
    deleteComment: (id: string) => `/projects/${id}/delete-comment`,
    changeBookingsOrder: '/projects/change-bookings-order',
    moveBookingsToProject: '/projects/move-bookings-to-project',
    deleteBookings: '/projects/delete-bookings',
    saveGoogleDriveForProject: '/projects/save-google-drive-for-project',
    saveGoogleDriveForBooking: '/projects/save-google-drive-for-booking',
  },
  bookings: {
    page: (filters: string) => `/bookings/page/${filters}`,
    forCalendar: (date: string) => `/bookings/for-calendar/${date}`,
    count: '/bookings/count-by-status',
    details: (id?: string) => id ? `/bookings/${id}`: '',
    create: '/bookings/create',
    updateQuestionnaire: (id: string) => `/bookings/${id}/update-questionnaire`,
    confirmQuestionnaireDates: (id: string) => `/bookings/${id}/confirm-questionnaire-dates`,
    updateTodos: (id: string) => `/bookings/${id}/update-todos`,
    delete: (id: string) => `/bookings/${id}/delete`,
    changeClient: (id: string) => `/bookings/${id}/change-client`,
    copy: "/bookings/copy",
    addVehicles: '/bookings/add-vehicles',
    deleteVehicles: '/bookings/delete-vehicles',
    hideVehicles: '/bookings/hide-vehicles',
    showVehicles: '/bookings/show-vehicles',
    updateVehicle: '/bookings/update-vehicle',
    updatePrepCall: '/bookings/update-prep-call',
    addCommentToVehicle: '/bookings/add-comment-to-vehicle',
    changeVehiclesOrder: '/bookings/change-vehicles-order',
    updateMiscellaneouses: (id: string) => `/bookings/${id}/update-miscellaneouses`,
    addComment: (id: string) => `/bookings/${id}/add-comment`,
    replyToComment: (id: string) => `/bookings/${id}/reply-to-comment`,
    deleteComment: (id: string) => `/bookings/${id}/delete-comment`,
    uploadDocument: (id: string) => `/bookings/${id}/upload-document`,
    deleteDocuments: (id: string) => `/bookings/${id}/delete-documents`,
    addTask: '/bookings/add-task',
    moveTask: '/bookings/move-task',
    updatePayment: '/bookings/update-payment',
  },
  handlers: {
    addHandler: '/handlers/add-handler',
    deleteVehiclesFromHandler: '/handlers/delete-vehicle-from-handler',
    addVehiclesToHandler: '/handlers/add-vehicles-to-handler',
    addComment: '/handlers/add-comment',
    update: '/handlers/update',
    delete: (id: string) => `/handlers/${id}/delete`,
    setIsPaid: '/handlers/set-is-paid',
    setIsVisibleForClient: '/handlers/set-is-visible-for-client',
  },
  todoes: {
    update: (id: string) => `/todo/update/${id}`,
    checkOrUncheck: (id: string) => `/todo/check-or-uncheck/${id}`,
    delete: (id: string) => `/todo/delete/${id}`,
  },
  bookingFlow: {
    changeStatus: (id: string) => `/booking-flow/${id}/change-status`,
  },
  settings: {
    get: '/settings/all',
    update: '/settings/update',
    addType: '/settings/add-type',
    addMake: '/settings/add-make',
  },
  mail: {
    list: '/api/mail/list',
    details: '/api/mail/details',
    labels: '/api/mail/labels',
  },
  post: {
    list: '/api/post/list',
    details: '/api/post/details',
    latest: '/api/post/latest',
    search: '/api/post/search',
  },
  product: {
    list: '/api/product/list',
    details: '/api/product/details',
    search: '/api/product/search',
  },
};
