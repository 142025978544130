import { forwardRef } from 'react';
// @mui
import Link from '@mui/material/Link';
import Box, { BoxProps } from '@mui/material/Box';
// routes
import { RouterLink } from 'src/routes/components';

// ----------------------------------------------------------------------

export interface LogoProps extends BoxProps {
  disabledLink?: boolean;
}

const Logo = forwardRef<HTMLDivElement, LogoProps>(
  ({ disabledLink = false, sx, ...other }, ref) => {
    const logo = (
      <Box
        ref={ref}
        component="div"
        sx={{
          width: 100,
          height: 100,
          display: 'inline-flex',
          ...sx,
        }}
        {...other}
      >
        <svg viewBox="0 0 256 256" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M235.263 24.5264H21.917C10.1383 24.5264 0.589844 33.9817 0.589844 45.6454V213.496C0.589844 225.16 10.1383 234.615 21.917 234.615H235.263C247.041 234.615 256.59 225.16 256.59 213.496V45.6454C256.59 33.9817 247.041 24.5264 235.263 24.5264Z" fill="#FC9D17"/>
          <path d="M244.535 209.823V49.318C244.535 42.2183 238.723 36.4629 231.553 36.4629L25.6258 36.4629C18.4562 36.4629 12.644 42.2183 12.644 49.318V209.823C12.644 216.923 18.4562 222.678 25.6258 222.678H231.553C238.723 222.678 244.535 216.923 244.535 209.823Z" stroke="#A77026" strokeWidth="6" strokeMiterlimit="10"/>
          <path d="M31.311 62.7247C35.1211 62.7247 38.2099 59.6661 38.2099 55.8931C38.2099 52.1201 35.1211 49.0615 31.311 49.0615C27.5008 49.0615 24.4121 52.1201 24.4121 55.8931C24.4121 59.6661 27.5008 62.7247 31.311 62.7247Z" fill="#221F20"/>
          <path d="M225.87 62.7247C229.68 62.7247 232.768 59.6661 232.768 55.8931C232.768 52.1201 229.68 49.0615 225.87 49.0615C222.059 49.0615 218.971 52.1201 218.971 55.8931C218.971 59.6661 222.059 62.7247 225.87 62.7247Z" fill="#221F20"/>
          <path d="M31.311 211.926C35.1211 211.926 38.2099 208.867 38.2099 205.094C38.2099 201.321 35.1211 198.263 31.311 198.263C27.5008 198.263 24.4121 201.321 24.4121 205.094C24.4121 208.867 27.5008 211.926 31.311 211.926Z" fill="#221F20"/>
          <path d="M225.87 211.926C229.68 211.926 232.768 208.867 232.768 205.094C232.768 201.321 229.68 198.263 225.87 198.263C222.059 198.263 218.971 201.321 218.971 205.094C218.971 208.867 222.059 211.926 225.87 211.926Z" fill="#221F20"/>
          <path d="M48.7793 67.949C48.7793 67.2184 49.0724 66.518 49.5941 66.0014C50.1157 65.4848 50.8233 65.1943 51.5611 65.1943H76.1522C77.0795 65.1943 85.5825 66.0575 90.4414 71.0067C95.3003 75.956 96.5428 84.9821 96.5428 86.8644V111.05C96.5428 112.887 95.7547 122.987 90.9792 127.89C86.2038 132.794 77.1351 132.849 76.1429 132.849H64.5985C64.2703 132.901 63.9572 133.022 63.6804 133.204C63.4036 133.386 63.1696 133.625 62.9943 133.905C62.5023 134.402 62.1951 135.049 62.1227 135.741V191.156C62.1227 192.13 61.7319 193.064 61.0363 193.753C60.3407 194.442 59.3973 194.829 58.4136 194.829L51.6075 194.737C50.8697 194.737 50.1621 194.447 49.6404 193.93C49.1187 193.413 48.8256 192.713 48.8256 191.982L48.7793 67.949ZM64.2275 118.139C64.2275 118.231 64.3481 118.277 64.5985 118.277C64.6456 118.28 64.6914 118.293 64.7329 118.315C64.7745 118.337 64.8108 118.368 64.8395 118.405H75.4104C76.2985 118.389 77.1697 118.162 77.9511 117.744C78.8327 117.3 79.6422 116.727 80.3527 116.045C81.0802 115.359 81.6881 114.559 82.1516 113.676C82.5831 112.866 82.815 111.966 82.8285 111.05V86.8644C82.815 85.9485 82.5831 85.0488 82.1516 84.2384C81.6881 83.3559 81.0802 82.5554 80.3527 81.8694C79.6402 81.1876 78.8312 80.6119 77.9511 80.1612C77.1674 79.7499 76.2972 79.5266 75.4104 79.5093H64.8766C64.5156 79.5294 64.1646 79.6351 63.8533 79.8173C63.5419 79.9994 63.2791 80.2527 63.087 80.5562C62.6107 81.1104 62.3306 81.8036 62.2896 82.5302V115.384C62.3202 115.993 62.5589 116.572 62.9665 117.028C63.2911 117.491 63.7237 117.868 64.2275 118.13V118.139Z" fill="#221F20"/>
          <path d="M107.356 67.949C107.356 67.2184 107.65 66.518 108.171 66.0014C108.693 65.4848 109.4 65.1943 110.138 65.1943H134.729C135.657 65.1943 144.15 66.0575 149.009 71.0067C153.868 75.956 155.12 84.9821 155.12 86.8644V111.05C155.12 112.887 154.323 122.987 149.556 127.89C144.79 132.794 135.712 132.849 134.72 132.849H123.166C122.837 132.9 122.522 133.02 122.243 133.202C121.965 133.385 121.729 133.624 121.553 133.905C121.066 134.404 120.763 135.051 120.691 135.741V191.156C120.691 192.13 120.3 193.064 119.604 193.753C118.909 194.442 117.965 194.829 116.981 194.829L110.175 194.737C109.438 194.737 108.73 194.447 108.208 193.93C107.687 193.413 107.394 192.713 107.394 191.982L107.356 67.949ZM122.805 118.139C122.805 118.231 122.925 118.277 123.176 118.277C123.27 118.288 123.356 118.334 123.417 118.405H133.988C134.873 118.39 135.741 118.163 136.519 117.744C137.404 117.3 138.216 116.727 138.93 116.045C139.655 115.359 140.259 114.558 140.72 113.676C141.158 112.868 141.393 111.967 141.406 111.05V86.8644C141.393 85.9472 141.158 85.0466 140.72 84.2384C140.259 83.3562 139.655 82.5556 138.93 81.8694C138.214 81.1876 137.402 80.6119 136.519 80.1612C135.74 79.7477 134.872 79.5243 133.988 79.5093H123.417C123.056 79.5294 122.705 79.6351 122.393 79.8173C122.082 79.9994 121.819 80.2527 121.627 80.5562C121.148 81.1095 120.865 81.8027 120.82 82.5302V115.384C120.855 115.993 121.097 116.573 121.507 117.028C121.84 117.497 122.286 117.875 122.805 118.13V118.139Z" fill="#221F20"/>
          <path d="M202.456 90.841C201.973 90.8412 201.51 90.6553 201.164 90.3227C200.817 89.9901 200.616 89.5371 200.601 89.0597C200.601 86.002 199.257 80.0976 189.613 80.7312C176.631 81.5851 178.579 89.7847 178.579 89.7847V170.588C178.579 170.588 180.303 178.962 190.475 178.788C200.647 178.614 200.481 171.277 200.481 171.277V168.844C200.481 168.357 200.676 167.89 201.024 167.545C201.372 167.201 201.843 167.007 202.335 167.007H212.09C212.582 167.007 213.054 167.201 213.401 167.545C213.749 167.89 213.944 168.357 213.944 168.844V176.915C213.944 176.915 213.611 194.664 187.74 194.664C171.522 194.664 164.79 182.534 164.79 173.986V84.643C164.79 84.643 166.172 64.3135 189.465 64.3135C212.758 64.3135 213.574 83.7889 213.574 83.7889V89.1054C213.574 89.5925 213.378 90.0595 213.03 90.4039C212.683 90.7483 212.211 90.9419 211.719 90.9419L202.456 90.841Z" fill="#221F20"/>
        </svg>
      </Box>
    );

    if (disabledLink) {
      return logo;
    }

    return (
      <Link component={RouterLink} href="/" sx={{ display: 'contents' }}>
        {logo}
      </Link>
    );
  }
);

export default Logo;
