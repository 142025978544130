// @mui
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';
// utils
import { fDate, fToNow } from 'src/utils/format-time';
// components
import Label from 'src/components/label';
import FileThumbnail from 'src/components/file-thumbnail';
import { BookingHistoryAction, IHistory } from 'src/types/history.interface';
import Iconify from 'src/components/iconify';

// ----------------------------------------------------------------------

type NotificationItemProps = {
  // notification: {
  //   id: string;
  //   title: string;
  //   category: string;
  //   createdAt: Date;
  //   isUnRead: boolean;
  //   type: string;
  //   avatarUrl: string | null;
  // };
  notification: IHistory;
};

export default function NotificationItem({ notification }: NotificationItemProps) {
  const renderAvatar = (
    <ListItemAvatar>
      {notification.author.avatarUrl ? (
        <Avatar src={notification.author.avatarUrl} sx={{ bgcolor: 'background.neutral' }} />
      ) : (
        <Stack
          alignItems="center"
          justifyContent="center"
          sx={{
            width: 40,
            height: 40,
            borderRadius: '50%',
            bgcolor: 'background.neutral',
          }}
        >
          <Box
            component="img"
            src={`/assets/icons/notification/${
              (notification.action === 'order' && 'ic_order') ||
              (notification.action === 'chat' && 'ic_chat') ||
              (notification.action === 'mail' && 'ic_mail') ||
              (notification.action === 'delivery' && 'ic_delivery')
            }.svg`}
            sx={{ width: 24, height: 24 }}
          />
        </Stack>
      )}
    </ListItemAvatar>
  );

  const renderText = (
    <ListItemText
      disableTypography
      primary={reader(notification.text || '')}
      secondary={
        <Stack
          direction="row"
          alignItems="center"
          sx={{ typography: 'caption', color: 'text.disabled' }}
          divider={
            <Box
              sx={{ width: 2, height: 2, bgcolor: 'currentColor', mx: 0.5, borderRadius: '50%' }}
            />
          }
        >
          {fToNow(notification.date)}
          {/* {notification.category} */}
        </Stack>
      }
    />
  );

  // const renderUnReadBadge = notification.isUnRead && (
  const renderUnReadBadge = (
    <Box
      sx={{
        top: 26,
        width: 8,
        height: 8,
        right: 20,
        borderRadius: '50%',
        bgcolor: 'info.main',
        position: 'absolute',
      }}
    />
  );

  const friendAction = (
    <Stack spacing={1} direction="row" sx={{ mt: 1.5 }}>
      <Button size="small" variant="contained">
        Accept
      </Button>
      <Button size="small" variant="outlined">
        Decline
      </Button>
    </Stack>
  );

  const projectAction = (
    <Stack alignItems="flex-start">
      <Box
        sx={{
          p: 1.5,
          my: 1.5,
          borderRadius: 1.5,
          color: 'text.secondary',
          bgcolor: 'background.neutral',
        }}
      >
        {reader(
          `<p><strong>@Jaydon Frankie</strong> feedback by asking questions or just leave a note of appreciation.</p>`
        )}
      </Box>

      <Button size="small" variant="contained">
        Reply
      </Button>
    </Stack>
  );

  const fileAction = (
    <Stack
      spacing={1}
      direction="row"
      sx={{
        pl: 1,
        p: 1.5,
        mt: 1.5,
        borderRadius: 1.5,
        bgcolor: 'background.neutral',
      }}
    >
      <FileThumbnail
        file="http://localhost:8080/httpsdesign-suriname-2015.mp3"
        sx={{ width: 40, height: 40 }}
      />

      <Stack spacing={1} direction={{ xs: 'column', sm: 'row' }} flexGrow={1} sx={{ minWidth: 0 }}>
        <ListItemText
          disableTypography
          primary={
            <Typography variant="subtitle2" component="div" sx={{ color: 'text.secondary' }} noWrap>
              design-suriname-2015.mp3
            </Typography>
          }
          secondary={
            <Stack
              direction="row"
              alignItems="center"
              sx={{ typography: 'caption', color: 'text.disabled' }}
              divider={
                <Box
                  sx={{
                    mx: 0.5,
                    width: 2,
                    height: 2,
                    borderRadius: '50%',
                    bgcolor: 'currentColor',
                  }}
                />
              }
            >
              <span>2.3 GB</span>
              <span>30 min ago</span>
            </Stack>
          }
        />

        <Button size="small" variant="outlined">
          Download
        </Button>
      </Stack>
    </Stack>
  );

  const tagsAction = (
    <Stack direction="row" spacing={0.75} flexWrap="wrap" sx={{ mt: 1.5 }}>
      <Label variant="outlined" color="info">
        Design
      </Label>
      <Label variant="outlined" color="warning">
        Dashboard
      </Label>
      <Label variant="outlined">Design system</Label>
    </Stack>
  );

  const paymentAction = (
    <Stack direction="row" spacing={1} sx={{ mt: 1.5 }}>
      <Button size="small" variant="contained">
        Pay
      </Button>
      <Button size="small" variant="outlined">
        Decline
      </Button>
    </Stack>
  );

  const renderChanges = (historyItem: IHistory) => {
    let result;
    if (!historyItem.changes || historyItem.changes.length < 1) {
      return result;
    }

    switch (historyItem.action) {
      case BookingHistoryAction.addVehicles:
        result = historyItem.changes?.map((change, changeIndex) =>
          <Stack direction="row" key={changeIndex}>
            <Iconify icon="mingcute:add-fill" color="green" />
            <Typography variant="body2">{change.to?.toString()}</Typography>
          </Stack>
        )
        break;
      case BookingHistoryAction.deleteVehicles:
        result = historyItem.changes?.map((change, changeIndex) =>
          <Stack direction="row" key={changeIndex}>
            <Iconify icon="mingcute:minimize-fill" color="red" />
            <Typography variant="body2">{change.to?.toString()}</Typography>
          </Stack>
        )
        break;
      case BookingHistoryAction.hideVehicles:
        result = historyItem.changes?.map((change, changeIndex) =>
          <Stack direction="row" key={changeIndex}>
            <Iconify icon="mingcute:minimize-fill" color="orange" />
            <Typography variant="body2">{change.to?.toString()}</Typography>
          </Stack>
        )
        break;
      case BookingHistoryAction.showVehicles:
        result = historyItem.changes?.map((change, changeIndex) =>
          <Stack direction="row" key={changeIndex}>
            <Iconify icon="mingcute:minimize-fill" color="blue" />
            <Typography variant="body2">{change.to?.toString()}</Typography>
          </Stack>
        )
        break;
      case BookingHistoryAction.updateVehicle:
        result = historyItem.changes?.map((change, changeIndex) =>
          <Stack direction="row" key={changeIndex}>
            <Typography variant="body2">{`${change.field  } ${change.from?.toString()}`}&#x2192;{change.to?.toString()}</Typography>
          </Stack>
        )
        break;
      case BookingHistoryAction.addNote:
        result = historyItem.changes?.map((change, changeIndex) =>
          <Stack direction="row" key={changeIndex}>
            <Typography variant="body2">{change.to?.toString()}</Typography>
          </Stack>
        )
        break;
      case BookingHistoryAction.updateNote:
        result = historyItem.changes?.map((change, changeIndex) =>
          <Stack direction="column" key={changeIndex}>
            <Typography variant="body2">{change.from?.toString()}</Typography>
            &#x2193;
            <Typography variant="body2">{change.to?.toString()}</Typography>
          </Stack>
        )
        break;
      case BookingHistoryAction.deleteNote:
        result = historyItem.changes?.map((change, changeIndex) =>
          <Stack direction="row" key={changeIndex}>
            <Typography variant="body2" color="red">{change.from?.toString()}</Typography>
          </Stack>
        )
        break;
      case BookingHistoryAction.addCommentToVehicle:
        result = historyItem.changes?.map((change, changeIndex) =>
          <Stack direction="row" key={changeIndex}>
            <Typography variant="body2">{change.to?.toString()}</Typography>
          </Stack>
        )
        break;
      case BookingHistoryAction.updateQuestionnaire:
        result = historyItem.changes?.map((change, changeIndex) =>
        <Stack direction="row" key={changeIndex}>
          <Typography variant="body2" color="orange">{change.field === 'endDate' || change.field === 'startDate' ? fDate(change.from?.toString()) : change.from?.toString()}</Typography>
          &#x2192;
          <Typography variant="body2" color="green">{change.field === 'endDate' || change.field === 'startDate' ? fDate(change.to?.toString()) : change.to?.toString()}</Typography>
        </Stack>
        )
        break;
      default:
        result = historyItem.changes?.map((change, changeIndex) =>
            <Stack direction="row" key={changeIndex}>
              <Typography variant="body2" color="orange">{change.from?.toString()}</Typography>
              &#x2192;
              <Typography variant="body2" color="green">{change.to?.toString()}</Typography>
            </Stack>
          )
        break;
    }
    return result;
  }

  return (
    <ListItemButton
      disableRipple
      sx={{
        p: 2.5,
        alignItems: 'flex-start',
        borderBottom: (theme) => `dashed 1px ${theme.palette.divider}`,
      }}
    >
      {renderUnReadBadge}

      {renderAvatar}

      <Stack sx={{ flexGrow: 1 }}>
        {renderText}
        {renderChanges(notification)}
        {/* {notification.type === 'friend' && friendAction}
        {notification.type === 'project' && projectAction}
        {notification.type === 'file' && fileAction}
        {notification.type === 'tags' && tagsAction}
        {notification.type === 'payment' && paymentAction} */}
      </Stack>
    </ListItemButton>
  );
}

// ----------------------------------------------------------------------

function reader(data: string) {
  return (
    <Box
      dangerouslySetInnerHTML={{ __html: data }}
      sx={{
        mb: 0.5,
        '& p': { typography: 'body2', m: 0 },
        '& a': { color: 'inherit', textDecoration: 'none' },
        '& strong': { typography: 'subtitle2' },
      }}
    />
  );
}
