// ----------------------------------------------------------------------

const ROOTS = {
  AUTH: '/auth',
  DASHBOARD: '/dashboard',
};

// ----------------------------------------------------------------------

export const paths = {
  // AUTH
  auth: {
    login: `${ROOTS.AUTH}/login`,
    register: `${ROOTS.AUTH}/register`,
    verify: `${ROOTS.AUTH}/verify`,
  },
  // DASHBOARD
  dashboard: {
    root: ROOTS.DASHBOARD,
    kanban: `${ROOTS.DASHBOARD}/kanban`,
    calendar: `${ROOTS.DASHBOARD}/calendar`,
    projects: {
      root:`${ROOTS.DASHBOARD}/projects`,
      new: `${ROOTS.DASHBOARD}/projects/new`,
      view: (id: string) => `${ROOTS.DASHBOARD}/projects/${id}`,
      selectVehicles: (id: string) => `${ROOTS.DASHBOARD}/projects/${id}/select-vehicles`,
    },
    oldProjects: {
      root:`${ROOTS.DASHBOARD}/old-projects`,
      view: (id: string) => `${ROOTS.DASHBOARD}/old-projects/${id}`,
    },
    bookings: {
      root:`${ROOTS.DASHBOARD}/bookings`,
      new: `${ROOTS.DASHBOARD}/bookings/new`,
      view: (id: string) => `${ROOTS.DASHBOARD}/bookings/${id}`,
      selectVehicles: (id: string) => `${ROOTS.DASHBOARD}/bookings/${id}/select-vehicles`,
      edit: (id: string) => `${ROOTS.DASHBOARD}/bookings/${id}/edit`,
    },
    invoices: `${ROOTS.DASHBOARD}/invoices`,
    vehicles: {
      root: `${ROOTS.DASHBOARD}/vehicles`,
      new: `${ROOTS.DASHBOARD}/vehicles/new`,
      newForVendor: (params: string) => `${ROOTS.DASHBOARD}/vehicles/new?${params}`,
      view: (id: string) => `${ROOTS.DASHBOARD}/vehicles/${id}`,
      edit: (id: string) => `${ROOTS.DASHBOARD}/vehicles/${id}/edit`,
    },
    vendors: {
      root: `${ROOTS.DASHBOARD}/vendors`,
      new: `${ROOTS.DASHBOARD}/vendors/new`,
      view: (id: string) => `${ROOTS.DASHBOARD}/vendors/${id}`,
      edit: (id: string) => `${ROOTS.DASHBOARD}/vendors/${id}/edit`,
    },
    clients: {
      root:`${ROOTS.DASHBOARD}/clients`,
      new: `${ROOTS.DASHBOARD}/clients/new`,
      account: (id: string) => `${ROOTS.DASHBOARD}/clients/account/${id}`,
      edit: (id: string) => `${ROOTS.DASHBOARD}/clients/${id}/edit`,
    },
    billingCompany: {
      details: (id: string) => `${ROOTS.DASHBOARD}/billing-company/${id}`,
      // edit: (id: string) => `${ROOTS.DASHBOARD}/billing-company/${id}/edit`,
    },
    users: {
      root: `${ROOTS.DASHBOARD}/users`,
      new: `${ROOTS.DASHBOARD}/users/new`,
      edit: (id: string) => `${ROOTS.DASHBOARD}/users/${id}/edit`,
      account: (id: string) => `${ROOTS.DASHBOARD}/users/account/${id}`,
    },
    documents: {
      root: `${ROOTS.DASHBOARD}/documents`,
    },
  },
};
