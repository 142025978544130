// routes
import { paths } from 'src/routes/paths';

// API
// ----------------------------------------------------------------------

export const HOST_API = process.env.REACT_APP_HOST_API;
export const PUBLIC_URL = process.env.REACT_APP_PUBLIC_URL;
export const ASSETS_API = process.env.REACT_APP_ASSETS_API;

export const MAPBOX_API = process.env.REACT_APP_MAPBOX_API;

// ROOT PATH AFTER LOGIN SUCCESSFUL
export const PATH_AFTER_LOGIN = paths.dashboard.bookings.root; // as '/dashboard'
